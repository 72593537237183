<script>
import Bg5 from "@/assets/img/bg5.jpg";
import GeneralMixin from "@/mixins/General";

import VueElementLoading from "vue-element-loading";
// import Reviews from './Reviews.vue'
import SpaceSchedule from "./SpaceSchedule.vue";
import SpaceLocationMixin from "@/mixins/SpaceLocation.js";
import EquipmentList from "./EquipmentList.vue";

export default {
  name: "ViewSpace",
  props: ["setAlertMessage"],
  components: {
    VueElementLoading,
    SpaceSchedule,
    // Reviews,
    EquipmentList,
  },

  data: function () {
    return {
      loading: false,
      bg5: Bg5,
    };
  },
  computed: {
    companyIsPaid() {
      if (this.space?.space_company?.has_paid) {
        return true;
      }
      return false;
    },
    all_spaces() {
      return this.$store.state.General.spaces;
    },
    space() {
      return this.$store.getters["General/getSpaceBySlug"](this.slug);
    },
    slug() {
      return this.$route.params.slug;
    },
  },
  methods: {
    getDefaultProduct(space) {
      return SpaceLocationMixin.getDefaultProduct(space);
    },
    getPlusCode(val) {
      if (!val) {
        return null;
      }
      const parts = val.split(" ");
      let part = parts[0];
      part = encodeURIComponent(part);
      return `https://www.google.com/maps/search/?api=1&query=${part}`;
    },
    padHours(val) {
      return GeneralMixin.padHours(val);
    },
    setLoaderStatus(v) {
      this.loading = v;
    },
    getHeroBackground(space) {
      var obj;
      if (space.homepage_image && space.homepage_image.pathname) {
        obj = {
          "background-image":
            'url("/media/' + space.homepage_image.pathname + '")',
        };
      } else {
        obj = {
          // background: 'radial-gradient(circle, #26992C, #007706)',
          background: "linear-gradient(90deg, #4caf50, #009d6b)",
        };
      }
      return obj;
    },
    getSpaceLogo(space) {
      if (space && space.logo) {
        return "/media/" + space.logo.pathname;
      }
      return this.bg5;
    },
    getWebsite(site) {
      if (site !== null && site !== undefined) {
        if (!site.startsWith("http")) {
          return "http://" + site;
        }
      }
    },
    isNonRepetitiveName: function (space) {
      var name1 = space.name;
      var name2 = "";
      if (space.space_company !== undefined) {
        name2 = space.space_company.company_name;
      }
      if (name1 === name2) {
        return false;
      }
      return true;
    },
  },
};
</script>

<template>
  <div id="ViewSpace" class="container-fluid">
    <div class="row justify-content-middle">
      <div id="right" class="col">
        <div class="container" id="right-container" v-if="space">
          <div class="row">
            <div class="col-12">
              <div
                class="row justify-content-center align-items-center text-center"
              >
                <div class="col" id="logo_holder">
                  <img
                    class="icon-image img-fluid"
                    :src="getSpaceLogo(space)"
                    alt=""
                  />
                </div>

                <div
                  class="col-12 mt-2 mt-md-0 col-md-10 text-center order-md-2 align-items-middle"
                  id="hero-area"
                  :style="getHeroBackground(space)"
                >
                  <div class="hero-bg"></div>
                  <div class="contents">
                    <h1 class="head-title">
                      <span class="head-text">
                        <div>
                          {{ space.name }}
                        </div>
                        <div
                          v-if="space.name !== space.space_company.company_name"
                        >
                          {{ space.space_company.company_name }}
                        </div>
                      </span>
                      <!-- <div class="bg_4_text"> -->
                      <!-- </div> -->
                    </h1>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-10 offset-md-2 features mt-3">
              <div class="row">
                <div
                  class="col-12 col-sm col-md col-xs-offset-1 col-sm-offset-0"
                >
                  <div
                    class="row no-gutters features-box wow fadeInDownQuick animated"
                    data-wow-delay="0.05s"
                    style="
                      visibility: visible;
                      -webkit-animation-delay: 0.05s;
                      -moz-animation-delay: 0.05s;
                      animation-delay: 0.05s;
                    "
                  >
                    <div class="col-2 text-right pr-3 features-icon">
                      <i
                        style="color: #647dee"
                        class="fas fa-map-marker-alt"
                      ></i>
                    </div>
                    <div class="col features-content">
                      <h4>DETAILS</h4>
                      <p>
                        <span class="fw-bold" v-if="getDefaultProduct(space)">
                          <i class="fas fa-money-bill"></i> KES
                          {{ getDefaultProduct(space).hourly_rate }}/=
                        </span>
                        per hour
                      </p>
                      <p>
                        <i class="fas fa-map-marker-alt"></i>
                        <template v-if="space.plus_code">
                          <a
                            :href="getPlusCode(space.plus_code)"
                            target="_blank"
                          >
                            {{ space.location }}
                          </a>
                        </template>
                        <template v-else>
                          {{ space.location }}
                        </template>
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  class="col-12 col-sm col-md col-xs-offset-1 col-sm-offset-0"
                >
                  <div
                    class="row no-gutters features-box wow fadeInDownQuick animated"
                    data-wow-delay="0.1s"
                    style="
                      visibility: visible;
                      -webkit-animation-delay: 0.1s;
                      -moz-animation-delay: 0.1s;
                      animation-delay: 0.1s;
                    "
                  >
                    <div class="col-2 text-right pr-3 features-icon">
                      <i style="color: #ff8489" class="fas fa-phone"></i>
                    </div>
                    <div class="col features-content">
                      <h4>CONTACT</h4>
                      <p>
                        <template v-if="space.website">
                          <div class="row">
                            <div class="col-4">Web:</div>
                            <div class="col">
                              <a
                                target="blank_"
                                :href="getWebsite(space.website)"
                                >{{ space.website }}</a
                              ><br />
                            </div>
                          </div>
                        </template>
                        <template v-if="space.phone_number">
                          <div class="row">
                            <div class="col-4">Phone:</div>
                            <div class="col">
                              <a :href="'tel:' + space.phone_number">{{
                                space.phone_number
                              }}</a>
                            </div>
                          </div>
                          <br />
                        </template>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="container">
                <div class="row justify-content-center">
                  <div class="text-center col-md-offset-3 col-md-6">
                    <router-link
                      v-if="companyIsPaid"
                      :to="{ name: 'Booking', params: { slug: space.slug } }"
                      class="btn btn-danger booking-btn"
                    >
                      MAKE A BOOKING
                    </router-link>
                    <span class="p-2 bg-secondary text-white" v-else>
                      Use Contacts above to make a booking.
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div id="sections" class="col-12 col-md-10 offset-md-2 text-center">
              <div class="row">
                <div id="sections-holder" class="col-12 text-center">
                  <section class="row">
                    <div class="col-12">
                      <h4>Schedule/Bookings</h4>
                    </div>
                    <div class="col-12 content">
                      <div
                        class="row"
                        id="schedule-row"
                        style="min-height: 50px"
                      >
                        <!-- <vue-element-loading :active="loading" spinner="bar-fade-scale" color="#64B963"/> -->
                        <vue-element-loading
                          :active="loading"
                          spinner="ring"
                          color="#64B963"
                        />
                        <space-schedule
                          @loaderChange="setLoaderStatus"
                          :space="space"
                          :setAlertMessage="setAlertMessage"
                        />
                      </div>
                    </div>
                  </section>

                  <!-- <section class="row"> -->
                  <!--   <div class="col-12"> -->
                  <!--     <h4>Photos</h4> -->
                  <!--   </div> -->
                  <!--   <div class="col-12"> -->
                  <!--     <router-link class="btn btn-success" :to="{name: 'Photos', params: {slug: space.slug}}">Click to view photos</router-link> -->
                  <!--   </div> -->
                  <!-- </section> -->

                  <section class="row">
                    <div class="col-12">
                      <h4>Equipment</h4>
                    </div>
                    <div class="col-12 content">
                      <div class="row">
                        <equipment-list
                          :space="space"
                          :setAlertMessage="setAlertMessage"
                        />
                      </div>
                    </div>
                  </section>

                  <!-- <section class="row"> -->
                  <!--     <div class="col-12"> -->
                  <!--         <h4>Reviews</h4> -->
                  <!--     </div> -->
                  <!--     <div class="col-12 content"> -->
                  <!--         <div class="row"> -->
                  <!--             <reviews :space="space" :setAlertMessage="setAlertMessage" /> -->
                  <!--         </div> -->
                  <!--     </div> -->
                  <!-- </section> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
#ViewSpace {
  #logo_holder {
    max-height: 200px;
    img {
      max-height: 120px;
    }
  }
  #left-nav {
    /*background: white;*/
    .item {
      margin-top: 6px;
      margin-bottom: 10px;
      a {
        padding: 5px;
        color: black;
      }
      .router-link-exact-active {
        background: white;
        color: #4caf50;
      }
    }
  }
  margin-top: 10px;
  padding-top: 10px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  background: #f6f6f6;
  box-sizing: border-box;
  .features-icon {
    i {
      font-size: 24px;
    }
  }
  .icon-image {
    max-height: 150px;
  }
  .head-title {
    padding-top: 10px;
    color: white;
    font-weight: bold;
    position: relative;
    .head-text {
      position: relative;
      z-index: 2;
    }
    .bg_4_text {
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      background: rgba(0, 0, 0, 0.3);
      color: white;
      z-index: 1;
    }
  }
  #sections {
    background: rgb(246, 246, 246);
    section {
      h4 {
        font-weight: bold;
        margin-bottom: 10px;
        color: rgb(76, 175, 80);
      }
      padding-top: 10px;
      padding-bottom: 10px;
      margin-top: 5px;
      .content {
        box-shadow: rgb(227, 228, 231) 0px 2px 0px 0px;
        border: 1px solid rgb(241, 241, 241);
        padding-top: 10px;
        padding-bottom: 10px;
        background: white;
      }
    }
  }
  .inner_nav {
    background-color: white;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    margin-bottom: 10px;
    ul > a.nav-item.item {
      font-weight: bold;
      &.router-link-exact-active {
        border-bottom: 1px solid black;
      }
      &:hover {
        text-decoration: None;
      }
    }
    .item {
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid white;
      &:hover {
        border-bottom: 1px solid black;
      }
    }
  }

  #hero-area {
    position: relative;
    background: url("https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.toptal.com%2Fdesigners%2Fsubtlepatterns%2Fpatterns%2Fdoodles.png&f=1&nofb=1");
    background-size: contain;
    background-position: 50% 50%;
    .contents {
      padding-top: 50px;
      padding-bottom: 30px;
    }
    .hero-bg {
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      background: linear-gradient(
        90deg,
        rgba(38, 153, 44, 0.9),
        rgba(0, 119, 6, 0.7)
      );
      background-color: #08e1ae;
      background: linear-gradient(
        90deg,
        rgba(35, 51, 41, 0.8),
        rgba(99, 212, 113, 0.7)
      );
      background: linear-gradient(
        90deg,
        rgba(35, 51, 41, 0.8),
        rgba(99, 212, 113, 0.7)
      );
      background: linear-gradient(
        90deg,
        rgba(99, 182, 100, 0.7),
        rgba(55, 139, 41, 0.8)
      );
    }
  }
  .features {
    padding: 20px;
    background: rgba(255, 255, 255, 0.9);
    border-bottom: 1px solid #eee;
  }
  .features-content {
    color: #888;
    font-family: montserrat, sans-serif;
    font-size: 13px;
    h4 {
      color: black;
      font-size: 20px;
    }
  }
  #right-container > div,
  #left .space {
    border: 1px solid rgb(241, 241, 241);
  }
  #left {
    padding-top: 15px;
    border-radius: 3px;
    margin-bottom: 5px;
    .title {
      color: #4caf50;
      margin-bottom: 5px;
    }
    .space {
      box-shadow: rgb(227, 228, 231) 0px 2px 0px 0px;
      display: block;
      margin-bottom: 5px;
      border-radius: 3px;
      padding-top: 10px;
      padding-bottom: 10px;
      &:hover {
        font-weight: bold;
      }
    }
  }
  .booking-btn {
    font-family: "Montserrat", sans-serif;
    box-shadow: rgb(227, 228, 231) 0px 2px 0px 0px;
    background: linear-gradient(45deg, #d7816a, #dc3545);
    border: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  }
}
</style>
